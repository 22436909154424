<template lang="pug">
.register(background-color="black")
  .px-6.my-16.text-center.white--text
    v-container
      h1.font Inscritos ({{ qtdComputed }})
      br
      v-data-table(:headers="headers" :items="inscritos" :items-per-page="10" dark locale="pt" disable-sort :loading="loading")
      br
      v-btn(@click.stop="createPdf()")
        v-icon(left) mdi-printer
        span Imprimir
</template>

<script>
import axios from 'axios'
import jspdf from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  data() {
    return {
      inscritos: [],
      loading: true,
      headers: [
        {
          text: 'Nome',
          value: 'nome'
        },{
          text: 'Idade',
          value: 'idade'
        }
      ]
    }
  },
  computed: {
    qtdComputed() {
      return this.inscritos.length
    }
  },
  async created() {
    const { data } = await axios.get('/api/subscribers')
    this.inscritos = data.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
    this.loading = false
  },
  methods: {
    createPdf() {
      const pdfHeaders = this.headers.map(x => x.text)
      const datas = this.inscritos.map(x => {
        return [
          x.nome, x.idade
        ]
      })
      const doc = new jspdf()
      autoTable(doc, {head: [pdfHeaders], body: datas})
      doc.save('Impressao.pdf')
    }
  }
}
</script>

<style>
.card {
  border: white solid 7px;
}
</style>