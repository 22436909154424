<template lang="pug">
.register(background-color="black")

  .px-6.my-16.text-center.white--text
    v-container
      v-row(justify="center" align="center")
        v-col.card(xl=4 lg=5 md=7 sm=11 xs=11)
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://forms.gle/y6tkoBMwsNHW1TjS7')")
            v-icon(left) mdi-form-select
            span Inscrição
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://drive.google.com/file/d/1O8o9IIcWqZ1JmHHwweKRkG7UiZdS_jM2/view?usp=sharing')")
            v-icon(left) mdi-human-male-boy
            span Autorização
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://drive.google.com/file/d/1nqgPcy9QBYLU3sSZJqDJPVrCNv8HuI6_/view?usp=sharing')")
            v-icon(left) mdi-bag-suitcase
            span Lista de bagagem
          v-btn.my-3.white--text(block, small, tile, color="#f11111" to="/links")
            v-icon(left) mdi-link-plus
            span Mais links
</template>

<script>
export default {
  methods: {
    openLink(url) {
      window.open(url, '_blank').focus()
    }
  }
}
</script>

<style>
.card {
  border: white solid 7px;
}
</style>