<template lang="pug">
.register(background-color="black")

  .px-6.my-16.text-center.white--text
    v-container
      h1.font Juventude da Primeira Igreja Batista de Santa Luzia
      h2.font.destaque.brilho Vós sois o sal da terra...
      v-carousel.my-3.card(
        continuous
        cycle
        show-arrows-on-hover
        hide-delimiter-background
        :height="$vuetify.breakpoint.mobile ? 300 : 800"
      )
        v-carousel-item(v-for="item in images" :key="item" :src="item")
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      images: [require('@/assets/home/img1.jpg'),require('@/assets/home/img2.jpg'),require('@/assets/home/img3.jpg'),require('@/assets/home/img4.jpg'),require('@/assets/home/img5.jpg'),require('@/assets/home/img7.jpg'),require('@/assets/home/img8.jpg'),require('@/assets/home/img9.jpg'),require('@/assets/home/img10.jpg'),require('@/assets/home/img11.jpg'),]
    }
  },
  methods: {
    getImg(item) {
      console.log(item)
      return item
    }
  }
};
</script>

<style>
.card {
  border: white solid 7px;
}
</style>