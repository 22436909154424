<template lang="pug">
.register(background-color="black")

  .px-6.card-centro.mb-16.text-center
    v-row(justify="center" align="center")
      v-col.card(xl=4 lg=5 md=7 sm=11 xs=11)
        ConferenceCard
</template>

<script>
import ConferenceCard from '../components/ConferenceCard.vue'
export default {
  name: "Inscrições",
  components: {
    ConferenceCard
  },
};
</script>

<style>
.card {
  border: white solid 7px;
}
</style>