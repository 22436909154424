<template lang="pug">
.register(background-color="black")

  .px-6.my-16.text-center.white--text
    v-container
      v-row(justify="center" align="center")
        v-col.card(xl=4 lg=5 md=7 sm=11 xs=11)
          v-btn.my-3.white--text(block, small, tile, color="#f11111" to="/acampamento")
            v-icon(left) mdi-playlist-check
            span Inscrições #AcampaSALT
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://www.youtube.com/channel/UCfa12Xrh9ZGDYMVYPTrjyKg')")
            v-icon(left) mdi-youtube-tv
            span Transmissão ao vivo
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://www.youtube.com/channel/UCfa12Xrh9ZGDYMVYPTrjyKg')")
            v-icon(left) mdi-youtube
            span Canal do Youtube
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://api.whatsapp.com/send?phone=5521964309526&text=Oi!%20Deus%20me%20tocou%20muito%20neste%20momento%20e%20segui%20o%20link%20do%20SALT%20para%20aceitar%20a%20Jesus!%20Como%20fa%C3%A7o%20isso%3F')")
            v-icon(left) mdi-account-voice
            span Quero aceitar Jesus
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://open.spotify.com/playlist/2tLtboc2cuAwDILt6hNXVy')")
            v-icon(left) mdi-spotify
            span Playlist do SALT
          v-btn.my-3.white--text(block, small, tile, color="#f11111" @click.stop="openLink('https://open.spotify.com/show/288am4oQ6Fobak5AotRcPA')")
            v-icon(left) mdi-podcast
            span Podcast do SALT
</template>

<script>
export default {
  methods: {
    openLink(url) {
      window.open(url, '_blank').focus()
    }
  }
}
</script>

<style>
.card {
  border: white solid 7px;
}
</style>