import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Register from '../views/Register.vue'
import Inscrito from '../views/Inscrito'
import Inscritos from '../views/Inscritos'
import Links from '../views/Links'
import Acampamento from '../views/Acampamento'
import Conferencia from '../views/Conferencia'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Início',
    component: Home
  },
  {
    path: '/inscrever',
    name: 'Inscrever',
    component: Links,
    beforeEnter() {
      window.location.href = "https://forms.gle/y6tkoBMwsNHW1TjS7";
    }
  },
  {
    path: '/acampamento',
    component: Acampamento,
  },
  {
    path: '/inscrito',
    name: 'Inscrito',
    component: Inscrito
  },
  {
    path: '/inscritos',
    name: 'Inscritos',
    component: Inscritos
  },
  {
    path: '/conferencia',
    name: 'Conferencia',
    component: Conferencia
  },
  {
    path: '/links',
    name: 'Links',
    component: Links
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
