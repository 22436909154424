<template lang="pug">
v-row.white--text
  v-col.pa-0(cols=12)
    v-row.border-bottom(dense, align="center")
      v-col(:cols="$vuetify.breakpoint.xs ? 12 : 5")
        div.text-center(:class='$vuetify.breakpoint.xs ? "mx-16 my-5" : "pa-7"')
          v-img(src="@/assets/seasons.png")
      v-col.border-left(style="background-color: white" :cols="$vuetify.breakpoint.xs ? 12 : 7")
        div(:class='$vuetify.breakpoint.xs ? "px-16 my-5" : "pa-9"')
          h1.align-center.font.black--text INSCRIÇÕES
  v-col(cols=12)
    .py-5
      h1.font Vem aí a conferência
      h1.font.destaque.brilho Estações
  v-col.white(cols=12)
    h1.font.black--text 13 Á 15 DE AGOSTO
  v-col(cols=12)
    h2.destaque.brilho.font PREPARA O CALENDÁRIO!
    br
    flip-countdown.ma-0.pa-0(deadline="2021-08-13 19:30:00" :labels="labels")
    br
    h2.font.mb-6 Inscrições serão liberadas em breve!
  v-col.white(cols=12)
    h2.font.black--text JUVENTUDE SALT • 2021
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'

export default {
  components: {
    FlipCountdown
  },
  data() {
    return {
      labels: {
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos'
      }
    }
  }
}
</script>

<style>
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  color: white !important
}

.text-field {
  border: #f11111 solid 2px;
  font-family: Fraktionmono, sans-serif;
}

.v-toast__text {
  font-family: Fraktionmono, sans-serif;
  text-transform: uppercase;
}

.logo-salt {
  filter: brightness(10);
}

.linha {
  width: 48px;
  height: 1px;
  margin-left: 15px;
  background-color: #f11111;
}

.canto {
  position: fixed;
  left: auto;
  top: auto;
  right: -135px;
  bottom: 210px;

  z-index: 9999;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 15px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;

  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);

  font-family: Fraktionmono, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.border-bottom {
  border-bottom: white solid 7px;
}
.border-top {
  border-top: white solid 7px;
}
.border-right {
  border-right: white solid 7px;
}
.border-left {
  border-left: white solid 7px;
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-centro {
  margin-top: 50px;
}

* {
  z-index: 0;
}
</style>